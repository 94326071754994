import React from 'react'
import styled from 'styled-components'

import SEO from '../../../components/SEO'
import HeroBlock from '../../../components/HeroBlock'
import WordArt from '../../../components/WordArt'
import AngledDivider from '../../../components/AngledDivider'
import ContentBlock from '../../../components/ContentBlock'
import FlexBox from '../../../components/FlexBox'
import InlineVideo from '../../../components/InlineVideo'
import ContactSection from '../../../components/ContactSection'

const playerLinks = [
  { url: 'https://player.vimeo.com/video/368842424?color=f93616' },
  { url: 'https://player.vimeo.com/video/374147839?color=f93616' },
  { url: 'https://player.vimeo.com/video/283535600?color=f93616' },
  { url: 'https://player.vimeo.com/video/349952117?color=f93616' },
  { url: 'https://player.vimeo.com/video/276091529?color=f93616' },
  { url: 'https://player.vimeo.com/video/339391407?color=f93616' }
]

const Video = styled(InlineVideo)`
  margin-bottom: 2rem !important;
  padding-bottom: 28.125%;
  width: 49%;
  @media (max-width: 768px) {
    padding-bottom: 56.25%;
    width: 96%;
  }
`

const VideoLibraryPage = () => (
  <React.Fragment>
    <SEO title="Video Library" />
    <HeroBlock backgroundColor="#0a0a0a">
      <HeroHeading />
    </HeroBlock>
    <AngledDivider color="#1b1b1b" position="top" />
    <ContentBlock backgroundColor="#1b1b1b">
      {/* group videos in pairs to render rows */}
      {playerLinks
        .reduce((pairs, link, index) => {
          var _pairs = pairs
          if (index % 2 === 0) {
            _pairs = pairs.concat([[link]])
          } else {
            _pairs[Math.floor(index / 2)] = _pairs[
              Math.floor(index / 2)
            ].concat([link])
          }
          return _pairs
        }, [])
        .map((pair, index) => (
          <FlexBox
            flexDirection="row"
            justifyContent="space-between"
            css={`
              flex-wrap: wrap;
            `}
          >
            <Video key={index} src={pair[0].url} />
            {pair[1] && <Video key={index * 2 + 1} src={pair[1].url} />}
          </FlexBox>
        ))}
    </ContentBlock>
    <AngledDivider color="#1b1b1b" position="bottom" />
    <ContactSection />
  </React.Fragment>
)

const HeroHeading = props => (
  <WordArt.Wrapper>
    <WordArt.Line color="#fff" fontSize="1.33em">
      Like Video?
    </WordArt.Line>
    <WordArt.Line color="#f93616" fontSize="1.55em">
      So Do We.
    </WordArt.Line>
  </WordArt.Wrapper>
)

export default VideoLibraryPage
