import React from 'react'

import FullWidthWrapper from './FullWidthWrapper'
import ParallaxWrapper from './ParallaxWrapper'

function HeroBlock({ height, minHeight, backgroundColor, children, ...props }) {
  return (
    <FullWidthWrapper
      height={height}
      minHeight={minHeight || '110vh'}
      {...props}
      css={`
        background-color: ${backgroundColor || 'unset'};
      `}
    >
      <ParallaxWrapper>{children}</ParallaxWrapper>
    </FullWidthWrapper>
  )
}

export default HeroBlock
